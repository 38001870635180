
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



import Home from './components/pages/Home';
import Footer from './components/inc/Footer';
import Contactus from './components/pages/Contactus';
import Alldoctor from './components/pages/Alldoctor';
import Biochemistry from './components/pages/Biochemistry';
import Aboutus from './components/pages/Aboutus';
import UserAuthContextProvider from './contexts/UserAuthContext';
import ProtectedRoute from './components/pages/ProtectedRoute';
import UserDashboard from './components/pages/UserDashboard';
import firebase from 'firebase/compat/app'
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';


import Tata from './components/pages/Tata';
import Xray from './components/pages/Xray';

import Profile from './components/pages/Profile';
import Medicine from './components/pages/Medicine';
import Clinicalpatho from './components/pages/Clinicalpatho';
import Histocytopathology from './components/pages/Histocytopathology';
import Hematology from './components/pages/Hematology';
import Immunology from './components/pages/Immunology';
import Microbiology from './components/pages/Microbiology';
import Molecular from './components/pages/Molecular';
import Cytogenetics from './components/pages/Cytogenetics';
import Serology from './components/pages/Serology';
import Ecg from './components/pages/Ecg';
import Usg from './components/pages/Usg';
import Opg from './components/pages/Opg';


firebase.initializeApp({
  apiKey: "AIzaSyCJBp-0Wmi-PfboH5ODwAFCsg5ruq7edGU",
  authDomain: "nadia-healthcare-service-fbfac.firebaseapp.com",
  projectId: "nadia-healthcare-service-fbfac",
  storageBucket: "nadia-healthcare-service-fbfac.appspot.com",
  messagingSenderId: "803600996516",
  appId: "1:803600996516:web:9bb44fd0b83c62d326ee31",
  measurementId: "G-W79QKVJXXF"
})

function App() {
  return (
    <Router>
     <UserAuthContextProvider>
    <div>

     
      
      <Routes>

   
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/Alldoctor" element={<Alldoctor/>}/>
      <Route axact path="/Biochemistry" element={<Biochemistry/>}/>
      <Route axact path="/Clinicalpathology" element={<Clinicalpatho/>}/>
      <Route axact path="/Histocytopathology" element={<Histocytopathology/>}/>
      <Route axact path="/Immunology" element={<Immunology/>}/>
      <Route axact path="/Hematology" element={<Hematology/>}/>
      <Route axact path="/Microbiology" element={<Microbiology/>}/>
      <Route axact path="/Molecular" element={<Molecular/>}/>
      <Route axact path="/Cytogenetics" element={<Cytogenetics/>}/>
      <Route axact path="/Serology" element={<Serology/>}/>

      <Route axact path="/Management" element={<Aboutus/>}/>
      <Route axact path="/login" element={<Login />} />
            <Route axact path="/signup" element={<Signup />} />
            <Route axact path="/Xray" element={<Xray/>} />
            <Route axact path="/ecg" element={<Ecg/>} />
            <Route axact path="/usg" element={<Usg/>} />
            <Route axact path="/opg" element={<Opg/>} />
            <Route axact path="/labtest" element={<Tata/>} />
            <Route axact path="/Medicine" element={<Medicine/>}/>
            <Route axact path="/company-profile" element={<Profile/>}/>

      <Route axact path="/UserDashboard/:userId" element={<ProtectedRoute> <UserDashboard /> </ProtectedRoute>} />
                  


      </Routes>
    <Footer/>
  
    </div>
    </UserAuthContextProvider>
    </Router>
  );
}

export default App;
