import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'

import ReactWhatsapp from "react-whatsapp";
import Navbar from "../inc/Navbar";






function Alldoctor() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(21);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [doctor, setDoctor] = useState([])
    const navigate = useNavigate()

    const doctorCollectionRef = collection(db, "Alldoctor");
    useEffect(() => {

        const getDoctor = async () => {
            const data = await getDocs(doctorCollectionRef);
            setDoctor(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getDoctor()
    }, [])
    return (

        <>
            <Navbar />
            <br />
            <br />
            <br/>
            {/* <br />
            <br />
            <br /> */}

            <div className="album py-2">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg> Back</Link></li>
                            <li style={{ color: "darkblue" }} className="breadcrumb-item"><Link style={{ color: "darkblue" }} to="/contact-us" >Contact Us</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Doctor <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg> </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4" >

                                <div className="album py-2">

                                    <div className="container">

                                        <div className="row">

                                            <div className="col-md-6">

                                                <div className="card-body">
                                                    <input type="text" className="form-control" placeholder="Search" name="location"
                                                        onChange={(e) => {
                                                            setsearchTerm(e.target.value);
                                                        }}
                                                    />
                                                    <div className="d-flex justify-content-between align-items-center">

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="album py-0">

                                    <div className="container">

                                        <div className="row">
                                            {doctor.filter((val) => {
                                                if (setsearchTerm === "") {
                                                    return val;
                                                } else if (
                                                    val.drname.toLowerCase().includes(searchTerm.toLowerCase())
                                                ) {
                                                    return val;
                                                }
                                            }).slice(pagination.start, pagination.end).map((nadiahealthcareservice) => {
                                                return (

                                                    <div className="row g-0">
                                                        <div className="col-md-5 mb-4 mt-3">
                                                            <img src={nadiahealthcareservice.img} className="img-fluid rounded-start me-0" alt="Arunachaltourism" />
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div className="card-body">
                                                                <h3 style={{color:"purple"}} className="card-title mb-1">{nadiahealthcareservice.drname} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                                                                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                                                                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                                                                </svg></h3>
                                                                <h6 className="card-text ">  {nadiahealthcareservice.speciality}</h6>
                                                                <p className="card-text ">  {nadiahealthcareservice.degree}</p>

                                                                <h5 className="card-text me-0"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                                                </svg> Reg. No. {nadiahealthcareservice.reg} </small></h5>
                                                                <p className="card-text ">  {nadiahealthcareservice.time}</p>
                                                                <a href='tel: +91 9775914827' className='buttonhome me-2'>Book Appointment</a>
                                                                <ReactWhatsapp number="+91 9775914827" className="buttonhome " message="I am Interested"> WhatsApp </ReactWhatsapp>

                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Alldoctor;


