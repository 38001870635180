// import React from 'react'
// import dcotors from "../images/doctor-bannar.jpg";

// import healthp from "../images/FULLBODYPACKAGE.jpg";

// function Slider() {
//   return (
//     <div>
//         <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
//   <div className="carousel-indicators">
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
//   </div>
//   <div className="carousel-inner">
//     <div className="carousel-item active">

//       <img src={healthp} className="d-block w-100" alt="health"/>
     
//     </div>
//     <div className="carousel-item">
//       <a href='tel: +91 98365 27206'>
//       <img src={dcotors} className="d-block w-100" alt="dcotors"/>
//       </a>
//     </div>
//     <div className="carousel-item">
//     <a href='tel: +91 98365 27206'>
//       <img src={dcotors} className="d-block w-100" alt="healthp"/>
//       </a>
//     </div>
//   </div>
//   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Previous</span>
//   </button>
//   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Next</span>
//   </button>
// </div>
//     </div>
//   )
// }

// export default Slider


import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'



function Slider() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [slider, setSlider] = useState([])
      const navigate = useNavigate()
  
      const sliderCollectionRef = collection(db, "Slider");
      useEffect(() => {
  
          const getSlider = async () => {
              const data = await getDocs(sliderCollectionRef);
              setSlider(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSlider()
      }, [])
  return (
    <div>

{slider.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((nadiahealthcareservice) => { return (
       
       
                       
                            <div className="card-body">
                                   
                                   <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={nadiahealthcareservice.slider1} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>{nadiahealthcareservice.title1}</h5>
        <p>{nadiahealthcareservice.des1}</p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={nadiahealthcareservice.slider2} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>{nadiahealthcareservice.title2}</h5>
        <p>{nadiahealthcareservice.des2}</p>
      </div>
    </div>
 
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  
                                
                               
                              
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={slider.length}
                /> */}
      
    </div>
  )
}

export default Slider
