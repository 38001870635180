import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";
import Navbar from "../inc/Navbar";
import "./About.css";





function Profile() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [companyprofile, setCompanyprofile] = useState([])
    const navigate = useNavigate()

    const companyprofileCollectionRef = collection(db, "2.A-Companyprofile");
    useEffect(() => {

        const getCompanyprofile = async () => {
            const data = await getDocs(companyprofileCollectionRef);
            setCompanyprofile(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getCompanyprofile()
    }, [])
    return (

        <>
            <Navbar />

            <br />
            
            <ScrollToTop smooth top="100" color="maroon" />

            <br />
            <br />
         

            <div className="album py-2">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg> Back</Link></li>
                            <li style={{ color: "darkblue" }} className="breadcrumb-item"><Link style={{ color: "darkblue" }} to="/contact-us" >Contact Us</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Company Profile<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg> </li>
                        </ol>
                    </nav>
                </div>
            </div>



            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {companyprofile.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((nadiahealthcareservice) => {
                            return (
                                <div className="album py-3">
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col-md-8 mt-2">
                                                <div className="card-body">
                                                    <h2 style={{ color: "maroon" }} className="card-title mb-2">{nadiahealthcareservice.name}</h2>

                                                    <p className="card-text justifytext">{nadiahealthcareservice.des}</p>
                                                    <p className="card-text justifytext">{nadiahealthcareservice.des2}</p>

                                                    <h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg> <b>{nadiahealthcareservice.des3}</b></h5>
                                                    <h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des4}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des5}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des6}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des7}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des8}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des9}</b></h5>
<h5 className="card-text justifytext"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg><b> {nadiahealthcareservice.des10}</b></h5>


                                                </div>

                                            </div>
                                            <div className="col-md-4 mt-2 mb-5">
                                                <div className="radiusimage">
                                                    <img src={nadiahealthcareservice.img} className="card-img-top" alt="nadiahealthcareservice" />
                                                </div>


                                            </div>
                                            <br />
                                            <br />
                                            <br />
                                            <br />

                                            <section class="section featured-car" id="mission">
                                            <div className="album py-3">
                                    <div className="container">
                                        <div className="row ">
                                           
                                                <div className="col-md-6 mt-2">
                                                    
                                                    <div className="card-body">
                                                        <img src={nadiahealthcareservice.missionimg} className="card-img-top" alt="mission-nhc" />

                                                    </div>

                                                </div>
                                                <br />
                                                <br />
                                                <div className="col-md-6 mt-2 mb-4">
                                                    <div className="card-body">
                                                        <h2 className="card-title mb-3"><b style={{ color: "darkBlue" }}>Our</b> Mission</h2>

                                                        <h5 className="card-text justifytext">{nadiahealthcareservice.missiontext}</h5>

                                                    </div>
                                                </div>

                                               
                                                </div>
                                                </div>
                                                </div>
                                                </section>
                                                
                                            <section class="section featured-car" id="Vision">
                                            <div className="album py-3">
                                    <div className="container">
                                        <div className="row ">
                                                <div className="col-md-6 mt-2">
                                                    <div className="card-body">
                                                        <h2 className="card-title mb-3"><b style={{ color: "darkBlue" }}>Our</b> Vision</h2>

                                                        <h5 className="card-text justifytext">{nadiahealthcareservice.visiontext}</h5>


                                                    </div>

                                                </div>
                                                <div className="col-md-6 mt-2">
                                                    <div className="card-body">

                                                        <img src={nadiahealthcareservice.visionimg} className="card-img-top" alt="mission-nhc" />

                                                    </div>

                                                </div>
                                                
                                                </div>
                                                </div>
                                                </div>
                                                </section>
                                        </div>
                                    </div>
                                </div>






                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Profile;



