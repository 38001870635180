import {React, useState, useEffect} from 'react';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { db } from '../../firebase'
import { deleteDoc ,doc,updateDoc, collection, onSnapshot } from "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyCJBp-0Wmi-PfboH5ODwAFCsg5ruq7edGU",
  authDomain: "nadia-healthcare-service-fbfac.firebaseapp.com",
  projectId: "nadia-healthcare-service-fbfac",
  storageBucket: "nadia-healthcare-service-fbfac.appspot.com",
  messagingSenderId: "803600996516",
  appId: "1:803600996516:web:9bb44fd0b83c62d326ee31",
  measurementId: "G-W79QKVJXXF"
})
const firestore = firebase.firestore()
const user = firebase.auth()
const address = firestore.collection("newusers")

// const booking = firestore.collection("Academybookings")
// const userLabtestBookingRef = collection(db, "Academybookings")

function Payment(data) {
    let bookingdata = data.data.filter(item => item.bookingDone === false)
    
    console.log(bookingdata)
    const [addressinfo, setAddressinfoInfo] = useState([]);

    
    useEffect(() => {
        const newref = address.doc(user.currentUser.uid).collection("address")
        onSnapshot(newref, (snapshot) => {
          let appointment = []
          snapshot.docs.forEach((doc) => {
            appointment.push({ ...doc.data(), id: doc.id })
          })
    
          setAddressinfoInfo(appointment)
          //  console.log(appointments)
        })
      }, [])

    console.log(data.paymentType)
    function loadScript(src) {
        return new Promise((resolve) => {
          const script = document.createElement('script')
          script.src = src
          script.onload = () => {
            resolve(true)
          }
          script.onerror = () => {
            resolve(false)
          }
          document.body.appendChild(script)
        })
      }
  const handleSubmit = async (event) => {
let totalprice = 0
    bookingdata.map((d)=>{
        totalprice += d.price
    })
    event.preventDefault();

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const options = {
      // key: 'rzp_test_m1pxa5JKJbAV4p',
      key: 'rzp_test_7dtmMmVxRIkdxI',
      currency: 'INR',
      amount: totalprice * 10,
      name: 'Evan Tarders Pvt Ltd',
      description: 'Razorpay Payment Gateway',
      image: "",
      handler: function (response) {
        alert(response.razorpay_payment_id)
        alert("Payment Succesfull")
        if (response.razorpay_payment_id) {
          bookingdata.map((i) => {
            let docRef = doc(db, 'Bookingsall', i.id);
            let usertestinfo = i
            console.log(usertestinfo)
            usertestinfo.bookingDone = true
            usertestinfo.paymentDone = true
            console.log(usertestinfo)
            // addDoc(userBookingRef, usertestinfo)
            updateDoc(docRef, usertestinfo).then(()=>{
              console.log("doc updated successfully")
            })
          })




        }


      },
      // if(response.razorpay_payment_id){

      // }
      prefill: {
        email: user.email,
      }
    }
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()

  };

  const handleSubmitOffline = async (event) => {
        event.preventDefault();
        let totalprice = 0
    bookingdata.map((d)=>{
        totalprice += d.price
    })
        bookingdata.map((i) => {
            console.log("-------------------------------------------------------------------")

            console.log(i.id)
            let docRef = doc(db, 'Bookingsall', i.id);
            let usertestinfo = i
            console.log(usertestinfo)
            usertestinfo.bookingDone = true
            console.log(usertestinfo)
            // addDoc(userBookingRef, usertestinfo)
            updateDoc(docRef, usertestinfo).then(()=>{
              console.log("doc updated successfully")

            })
          })
          alert("your booking is successfull... Congratulations!! It will be delivered soon to your address::")

    console.log(bookingdata)
  }
  const handleSubmitNot = async (event) => {
    event.preventDefault();
    alert("Please select your payment method or Add shipping address")
console.log(bookingdata)
}
  if (data.paymentType && addressinfo[0]){
    return (
        <form onSubmit={data.paymentType === "Cash On Delivery" ? handleSubmitOffline : handleSubmit}>
          {/* Other form fields go here */}      
          {/* Submit button */}
          <button className='btn btn-primary btn-sm' type="submit">Proceed to Checkout</button>
        </form>
      );
  }else{
    return (
        <form onSubmit={handleSubmitNot}>
          {/* Other form fields go here */}      
          {/* Submit button */}
          <button className='btn btn-primary btn-sm' type="submit">Proceed to Checkout</button>
        </form>
      );
  }

}


function RemoveBooking(id) {
  // Define a function to be executed when the button is clicked
  const handleClick = async () => {
  console.log(id.id)
    const documentRef = doc(db, 'Bookingsall', id.id);

    try {
      await deleteDoc(documentRef);
      console.log('Document successfully deleted');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }

  };
  return (
    <div>
      <button onClick={handleClick}className='btn btn-danger btn-sm'>Remove</button>
    </div>
  );
}


export  {Payment};
