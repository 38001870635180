import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import helpline from "../images/helpline.jpg";
import AddEnquiry from '../AddEnquiry';
function Contactus() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            {/* <br />
            <br /> */}
            <ScrollToTop smooth top="100" color="black" />

            <div className='album py-2'>
                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{ color: "black" }} className="breadcrumb-item"><Link to="/" style={{ color: "black" }} >Home</Link></li>

                            <li className="breadcrumb-item active" aria-current="page">Contact Us<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='album py-2'>
                <div className='container'>
                    <div className="row">


                        <div className="col-md-4 mt-8" >


                            <div className="card-body">
                                <hr style={{ color: "black" }} />




                            </div>

                        </div>
                        <div className="col-md-4 mt-8" >


                            <div className="card-body">

                                <h3 className="card-title"><center>CONTACT US</center></h3>

                            </div>

                        </div>
                        <div className="col-md-4 mt-8" >


                            <div className="card-body">
                                <hr style={{ color: "black" }} />


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='album py-2'>
                <div className='container'>
                    <div className="row">


                        <div className="col-md-4 mt-8" >
                            <div className="card text-white bg-info mb-3" >
                                <div className="card-header"><center><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" style={{ color: "black" }} className="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                </svg></center></div>
                                <div className="card-body">
                                    <br />
                                    <h4 className="card-title mb-3"><center>Address</center></h4>
                                    <center>
                                        <p className="card-text mb-2"> Karimpur Jagannath High School Beside, Karimpur Bazaar (Indian Oil)</p>
                                        <p className="card-text">   Pin-741152, Dist- NadiaWest Bengal | India</p>
                                    </center>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-8" >
                            <div className="card text-dark bg-info mb-3" >
                                <div className="card-header"><center><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" style={{ color: "black" }} className="bi bi-telephone-forward" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708" />
                                </svg></center></div>
                                <div className="card-body">
                                    <br />
                                    <center>
                                        <h3 className="card-title mb-3">Phone</h3>

                                        <a href="tel: +91 98365 27206" className="btn btn-light mb-3">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-forward" viewBox="0 0 16 16">
                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
                                        </svg> +91 98365 27206</a>

                                    </center>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-8" >
                            <div className="card text-white bg-info mb-3" >
                                <div className="card-header"><center><svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" fill="currentColor" style={{ color: "black" }} className="bi bi-envelope-check" viewBox="0 0 16 16">
                                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686" />
                                </svg></center></div>
                                <div className="card-body">
                                    <br />
                                    <center>
                                        <h3 className="card-title mb-3">Email</h3>
                                        <a className='btn btn-light mb-3' href='mailto: help@nadiahelthcareservice.com'>help@nadiahelthcareservice.com</a>

                                    </center>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='album py-2'>
                <div className='container'>
                    <div className="row">

                        <div className="col-md-4 mt-3" >
                       
                                <img src={helpline} className="card-img-top" alt="helpline" />
                                <div className="card-body">
                                    
                              
                            </div>
                        </div>
                        <div className="col-md-8 mt-3" >
                            <center><h5 className="card-title mb-5">Conatct Us</h5></center>
                            <p className="card-text">Nadia Healthcare Service Hospitals is always looking to make things easier for you. Our aim is to provide our customers with the best medical facilities, constant care, and reliable support. If you would like to get in touch with a doctor from a specific department,</p>

                              
                                <div className="card-body">
                                    <br/>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item" role="presentation">
    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Enquiry</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Complaint</button>
  </li>
  <li className="nav-item" role="presentation">
    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Feedback</button>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
    <br/>
    <AddEnquiry/>
    </div>
  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <br/>
    <AddEnquiry/>
  </div>
  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
    <br/>
    <AddEnquiry/>
  </div>
</div>
                                    
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="album py-2">

                <div className="container">

                    <div className="row">





                    </div>
                </div>
            </div>
            <br />

            {/* <div className="container">

            <div className="row">

                            <iframe className="iframe-fluid"  height="440" src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=M.%20B.%20DHAR%20%26%20SON%20JEWELLERS%2C%20127%2C%20Doihatta%20Rd%2C%20Sonapotti%2C%20Khagra%2C%20Berhampore%2C%20West%20Bengal%20742103+(M.%20B.%20DHAR%20%26%20SON%20JEWELLERS%2C%20127%2C%20Doihatta%20Rd%2C%20Sonapotti%2C%20Khagra%2C%20Berhampore%2C%20West%20Bengal%20742103)&amp;ie=UTF8&amp;t=&amp;z=11&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> 
                           
                            </div>
                            </div> */}

        </div>
    )
}

export default Contactus