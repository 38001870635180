import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import Doctorpage from "../images/dcotor_page.jpg";
import Diagnostics from "../images/diagnostics.jpg";
import XRay from "../images/x-ray.jpg";
import healthpackage from "../images/health-package.jpg";
import AddPatient from '../AddPatient';
// import Notice from './Notice';
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import opgimage from "../images/opg-image.jpg";
import Ecg from "../images/ECG-IMAGE.jpg";
import Medicine from "../images/medicine-image.jpg";


function Home() {
  return (
    <div>
      <Navbar />
      <br />
     
    
      <div className="album py-0">
        <div className="container">
          <h5>Nadia Healthcare Service</h5>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="darkgoldenrod" />
      <div className='nadiahealthcare'>
        <marquee><h5 style={{ color: "white" }} className='mt-2'>Welcome To Nadia Healthcare Service | For X-Ray, Ultrasound Scan, 2D echo, CT scan & MRI</h5></marquee>
      </div>
      <Slider />

      <div className="album py-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-3 mt-2">
              <div className="card">
                <Link to="/Alldoctor">
                  <img src={Doctorpage} className="card-img-top " alt="nadia_healthcare_doctor" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title mb-0">Doctor</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <Link to="/Alldoctor">
                    <a href="#" className="buttonhome">Learn More</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >
                <Link to="/labtest">
                  <img src={Diagnostics} className="card-img-top" alt="Diagnostics" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title mb-0">Labtest</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <Link to="/labtest" className="buttonhome">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >
                <Link to="/Xray">
                  <img src={XRay} className="card-img-top" alt="xray" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title mb-0">X-Ray</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <a href="/Xray" className="buttonhome">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-2">
              <div className="card" >
                <Link to="/usg">
                  <img src={healthpackage} className="card-img-top" alt="healthpackage" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title mb-0">USG</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <a href="/usg" className="buttonhome">Learn More</a>
                </div>
              </div>
            </div>

            <div className="col-md-3 mt-2">
              <div className="card" >
                <a href='opg'>
                  <img src={opgimage} className="card-img-top" alt="nadia_healthcare_service" />
                </a>
                <div className="card-body">
                  <h5 className="card-title mb-0">OPG</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <a href="/opg" className="buttonhome">Learn More</a>
                </div>
              </div>
            </div>

   


            <div className="col-md-3 mt-2">
              <div className="card" >
                <Link to="/ecg">
                  <img src={Ecg} className="card-img-top" alt="nadia_healthcare_service" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title mb-0">ECG</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <a href="/ecg" className="buttonhome">Learn More</a>
                </div>
              </div>
            </div>

            <div className="col-md-3 mt-2">
              <div className="card" >
                <a href='Medicine'>
                  <img src={Medicine} className="card-img-top" alt="nadia_healthcare_service" />
                </a>
                <div className="card-body">
                  <h5 className="card-title mb-0">Medicine</h5>
                  <p className="card-text mb-3">Some content.</p>
                  <a href="/Medicine" className="buttonhome">Learn More</a>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div className="besttourismnadia">

        <div className="album py-0">
          <div className="container">
            <div className="row ">
              <div className="col-md-7" >
                <br />
                <br />
                <h3 className='mb-2'><b>Worried about going out for ablood test?</b></h3>
                <h2 style={{ color: "darkBlue" }}><b>Relax you will come to you</b></h2>

                <h3 className='mb-2'><b> রক্ত পরীক্ষার জন্য বাইরে যাওয়া নিয়ে চিন্তিত?</b></h3>

              </div>
              <div className="col-md-5" >
                <br />
                <br />

                {/* <AddPatient /> */}
                <iframe  className="iframe-fluid"  height="405"  id="gmap_canvas" src="https://nadiahealthcareservice.netlify.app/" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Notice /> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='nadiahealthcare'>
        <div className='album py-1'>
          <div className='container'>
            <div className="row">
              <div className="col-md-3">
                <a href='Alldoctor'>
                  <h4 style={{ color: "white" }} className='text mt-2 mb-0'>Book Appointment <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></h4>
                  <p style={{ color: "white" }} ><small>Cooche by name, speciality, city</small></p>
                </a>
              </div>

              <div className="col-md-3">
                <a href='Medicine'>
                <h4 style={{ color: "white" }} className='text mt-2 mb-0'>Buy Medicines <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                </svg></h4>
                <p style={{ color: "white" }} ><small>Order online</small></p>
                </a>
              </div>

              <div className="col-md-3">
                <h4 style={{ color: "white" }} className='text mt-2 mb-0'>Book Health Checkup <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                </svg></h4>
                <p style={{ color: "white" }} ><small>Cooche by location, name, age, gender</small></p>
              </div>

              <div className="col-md-3">
                <a href='Alldoctor'>
                  <h4 style={{ color: "white" }} className='text mt-2 mb-0'>Find Doctor <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg></h4>
                  <p style={{ color: "white" }} ><small>Find doctor, name, speciality</small></p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home