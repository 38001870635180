// import React from 'react'
// import { Link } from "react-router-dom";
// import "./Navbar.css";
// import Logo from "../images/logonadia.jpg";

// function Navbar() {
//   return (
//     <div>
//       <div className='fixed-top'>
//         <div className='colors'>
//           <div className="container">
//             <div className="row ">
//               <div className="col-md-5" >
//                 <div className="d-flex justify-content-between align-items-center">
//                   <a href='tel: +91 XXXXXXXX'>+91 XXXXXXXX  <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
//                     <span class="visually-hidden">Loading...</span></a>
//         <button style={{color:"white"}} className='button button1'>HOME COLLECTION*</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
//           <div className="container-fluid ">
//             <Link to="/">
//             <img src={Logo} height="40" width="110" alt="Logo" />
//             </Link>
//             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//               <span className="navbar-toggler-icon"></span>
//             </button>
//             <div className="collapse navbar-collapse" id="navbarSupportedContent">
//               <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
//                 <li className="nav-item">
//                   <Link to="/" style={{ color: 'darkblue' }} className="nav-link active" aria-current="page" href="#"><small>HOME</small></Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <a style={{ color: 'darkblue' }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                     <small>ABOUT US</small>
//                   </a>
//                   <ul className="dropdown-menu" aria-labelledby="navbarDropdown">

//                     <li><Link to="#" className="dropdown-item" href="#">Company Profile</Link></li>


//                     <li><Link to="#" className="dropdown-item" href="#">Mission & Vision</Link></li>
//                     <li><Link to="/Management" className="dropdown-item" href="#">Management</Link></li>


//                   </ul>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <a style={{ color: 'darkblue' }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                     <small>FOR PATIENT</small>
//                   </a>
//                   <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                     <li><a className="dropdown-item" href="#">Lab Test</a></li>
//                     <li><Link to="#" className="dropdown-item" href="#">Medicine</Link></li>
//                     <li><a className="dropdown-item" href="#">X-Ray</a></li>
//                     <li><hr className="dropdown-divider" /></li>
//                     <li><Link to="#" className="dropdown-item" href="#">USG</Link></li>
//                     <li><Link to="#" className="dropdown-item" href="#">OPG</Link></li>
//                     <li><Link to="#" className="dropdown-item" href="#">ECG</Link></li>

//                   </ul>
//                 </li>
//                 <li className="nav-item">
//                   <Link to="/Alldoctor" style={{ color: 'darkblue' }} className="nav-link" href="#"><small>FIND DOCTOR</small></Link>
//                 </li>

//                 <li className="nav-item">
//                   <Link to="/" style={{ color: 'darkblue' }} className="nav-link" href="#"><small>LABTEST</small></Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <a style={{ color: 'darkblue' }} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                     <small> DEPARTMENT</small>
//                   </a>
//                   <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
//                     <li><Link to="/Biochemistry" className="dropdown-item" >Biochemistry</Link></li>
//                     <li><Link to="#" className="dropdown-item" >Clinical Pathology</Link></li>
//                     <li><Link to="#" className="dropdown-item" >Histocytopathology</Link></li>
//                     <li><Link to="#" className="dropdown-item" >Hematology</Link></li>
//                     <li><Link to="#" className="dropdown-item" >Immunology</Link></li>


//                   </ul>
//                 </li>
//                 <li className="nav-item">
//                   <Link to="/contact-us" style={{ color: 'darkblue' }} className="nav-link" href="#"><small>CONTACT</small></Link>
//                 </li>
//               </ul>


//               <button className="employeelogin" type="submit">Employe Login <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
//                 <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z" />
//                 <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
//               </svg></button>

//             </div>
//           </div>
//         </nav>
//       </div>
//     </div>
//   )
// }

// export default Navbar







import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-react-ui-kit';

import './Navbar.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'

import Logo from "../images/LOGONHC.jpg"

function Navbars(props) {



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()
  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}

      <div className='fixed-top'>
        {/* <div className='colors'>
          <div className="container">
            <div className="row ">
              <div className="col-md-5" >
                <div className="d-flex justify-content-between align-items-center">
                  <a href='tel: +91 XXXXXXXX'>+91 9836527206 <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span></a>
                    <a href='tel: 9836527206'>
                  <button style={{ color: "white" }} className='button button1'>HOME COLLECTION*</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
              <Link to="/">
                <img src={Logo} height="30" width="70" alt="Logo" />
              </Link>

            </Navbar.Brand>
            <div className="text-end d-grid gap-0 d-md-flex justify-content-md-start">

              {!user ? (

                <div>

                  <Link to="/Login">
                    <button type="button" className="btn btn-warning btn-sm me-md-1 ">LOGIN</button>
                  </Link>

                </div>
              ) :
                <>

                  <Dropdown className='dropdown'>
                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                      <img className='userphoto' src={user.photoURL} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <center>
                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='info btn-sm'><small>Dashboard</small></Button>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='success btn-sm'>Upload </Button>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
                        </Dropdown.Item>
                      </center>
                    </Dropdown.Menu>

                  </Dropdown>
                </>

              }

            </div>
            {/* <Nav.Link className="text"><Link to="/Form"><b style={{ color: "grey" }}>Cart <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg> </b></Link></Nav.Link> */}

            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} className='btn btn-primary btn-sm me-1'>Cart <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
            </svg></Button>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="mx-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page" href="#">Home</Link>
                </li>
                <MDBContainer fluid>
        <MDBNavbarNav>
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag='a' className='nav-link'>
            Our Services 
              </MDBDropdownToggle>
              <MDBDropdownMenu>
               
               

                <MDBDropdownItem>
                <center>
                  <a className='mt-4' href="">Pathology &raquo;</a>
                  </center>
                  <ul className="dropdown-menu dropdown-submenu">
                  <MDBDropdownItem>
                      <center>
                      <Link to="/Histocytopathology" href="#">Histocytopathology</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Clinicalpathology" href="#">Clinical Pathology</Link>
                      </center>
                    </MDBDropdownItem>
               
                    <MDBDropdownItem>
                    <center>
                      <Link to="/Biochemistry" href="#">Biochemistry</Link>
                      </center>
                    </MDBDropdownItem>
                 
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Microbiology" href="#">Microbiology</Link>
                      </center>
                    </MDBDropdownItem>
                    
                
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Immunology" href="#">Immunology</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Cytogenetics" href="#">Cytogenetics</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Serology" href="#">Serology</Link>
                      </center>
                    </MDBDropdownItem>
                  </ul>
                </MDBDropdownItem>
                <hr className='mb-2'/>
                <MDBDropdownItem>
                <center>
                  <a className='mt-4' href="#">Radiology &raquo;</a>
                  </center>
                  <ul className="dropdown-menu dropdown-submenu">
                    <MDBDropdownItem>
                      <center>
                      <Link to="/Xray" href="#">X-Ray</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                    <center>
                      <Link to="/usg" href="#">USG</Link>
                      </center>
                    </MDBDropdownItem>
                 
                    <MDBDropdownItem>
                      <center>
                      <Link to="/opg" href="#">OPG</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <center>
                      <a href="#">CTS</a>
                      </center>
                    </MDBDropdownItem>
                  </ul>
                </MDBDropdownItem>
                <hr className='mb-2'/>
                <MDBDropdownItem>
                <center>
                  <a className='mt-4' href="#">Cardiology &raquo;</a>
                  </center>
                  <ul className="dropdown-menu dropdown-submenu">
                    <MDBDropdownItem>
                      <center>
                      <Link to="/ecg" href="#">ECG</Link>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                    <center>
                      <a href="#">TMT</a>
                      </center>
                    </MDBDropdownItem>
                 
                    <MDBDropdownItem>
                      <center>
                      <a href="#">PFT</a>
                      </center>
                    </MDBDropdownItem>
                  
                  </ul>
                </MDBDropdownItem>
                <hr className='mb-2'/>
                <MDBDropdownItem>
                <center>
                  <a className='mt-4' href="#">Neurology &raquo;</a>
                  </center>
                  <ul className="dropdown-menu dropdown-submenu">
                    <MDBDropdownItem>
                      <center>
                      <a href="#">EEG</a>
                      </center>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                    <center>
                      <a href="#">EMG</a>
                      </center>
                    </MDBDropdownItem>
                 
                    <MDBDropdownItem>
                      <center>
                      <a href="#">NCV</a>
                      </center>
                    </MDBDropdownItem>
                  
                  </ul>
                </MDBDropdownItem>
            
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
           

                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> About Us </small>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/company-profile" className="dropdown-item" >Company Profile</Link></li>
                    <li> <a href='company-profile#mission' className="dropdown-item" >Mission</a></li>
                    <li> <a href='company-profile#Vision' className="dropdown-item" >Vision</a></li>


                    <li><hr className="dropdown-divider" /></li>
                    <li><Link to="/Management" className="dropdown-item" href="#">Management</Link></li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <small> For Patient</small>

                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><Link to="/Labtest" className="dropdown-item" >Home Sapmple Collection </Link></li>
                    <li><Link to="/Medicine" className="dropdown-item" >Medicine </Link></li>





                  </ul>
                </li>

               
      
    


              
                <li className="nav-item">
                  <Link to="/labtest" className="nav-link " aria-current="page" href="#"> Labtest</Link>
                </li>
                <li className="nav-item">
                  <Link to="/Alldoctor" className="nav-link " aria-current="page" href="#"> Doctors</Link>
                </li>
            
                <li className="nav-item">
                  <Link to="/contact-us" className="nav-link " aria-current="page" href="#"><small> Contact </small></Link>
                </li>
                {/*             
                <NavDropdown title="FMCG" id="basic-nav-dropdown">
              <NavDropdown.Item href="/Dukes">Dukes</NavDropdown.Item>
              <NavDropdown.Item href="/Hunumanta">
              Hunumanta
              </NavDropdown.Item>
              <NavDropdown.Item href="Dalmia"> Dalmia</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/tataproduct">
                Tata
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Agriculture" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Poltry</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Egg
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Dairy</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Farm
              </NavDropdown.Item>
            </NavDropdown>
                <Nav.Link className="text"> <Link to="/contact"><b style={{ color: "maroon" }}>Contact Us
                </b></Link></Nav.Link> */}


              </Nav>
              <Link to="#" className="downloadbutton me-2"> <small>  Download Brochure</small></Link>
              <a href="http://nadiahealthcareservice.co.in/Login.aspx" target="_blank" rel="noopener noreferrer" className="loginbutton"> <small>  EMPLOYEE LOGIN</small></a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default Navbars;


