import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

import ScrollToTop from "react-scroll-to-top";
import Navbar from "../inc/Navbar";
import "./About.css";





function Xray() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(9);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [xray, setXray] = useState([])
    const navigate = useNavigate()

    const xrayCollectionRef = collection(db, "1.C-Xray");
    useEffect(() => {

        const getXray = async () => {
            const data = await getDocs(xrayCollectionRef);
            setXray(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getXray()
    }, [])
    return (

        <>
            <Navbar />

            <br />
            <ScrollToTop smooth top="100" color="maroon" />

            <br />
            <br />
            <br />
            <br />

            <div className="album py-2">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg> Back</Link></li>
                            <li style={{ color: "darkblue" }} className="breadcrumb-item"><Link style={{ color: "darkblue" }} to="/contact-us" >Contact Us</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">X-ray<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg> </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <center>
                <h3 style={{color:"darkblue"}}><b>XRAY </b>SERVICES</h3>
            </center>

            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {xray.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((nadiahealthcareservice) => {
                            return (
                                <div className="album py-3">
                                    <div className="container">
                                        <div className="row ">
                                          
                                            <div className="col-md-8 mt-2">


                                                <div className="card-body">
                                                    <h2 style={{color:"purple"}} className="card-title mb-4"><b>{nadiahealthcareservice.title}</b></h2>
                                                    <p className="card-text justifytext">{nadiahealthcareservice.des}</p>

                                                    <p className="card-text justifytext">{nadiahealthcareservice.des2}</p>

                                                </div>

                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <div className="radiusimage">
                                                    <img src={nadiahealthcareservice.img} className="card-img-top" alt="..." />
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>


                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Xray;



